
import ReviewPersonalSection from './components/ReviewPersonalSection'

export default {
    name: 'Review-Personal',
    components: {
        ReviewPersonalSection,
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleTip() {
            this.$router.push({
                name: 'Policy',
                query: {
                    id: '1691130000334mnzEudIx'
                }
            })
        }
    },
}
