import { render, staticRenderFns } from "./ShareTop.vue?vue&type=template&id=ce65ae40&scoped=true&"
import script from "./ShareTop.vue?vue&type=script&lang=js&"
export * from "./ShareTop.vue?vue&type=script&lang=js&"
import style0 from "./ShareTop.vue?vue&type=style&index=0&id=ce65ae40&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce65ae40",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnRate: require('/home/php1/m-nuxt/components/YfnRate.vue').default,YfnSwiper: require('/home/php1/m-nuxt/components/YfnSwiper.vue').default})
