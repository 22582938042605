
export default {
    name: 'ReviewPersonalList',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: false,
            skeleton: true,
            orderState: 'WaitComment',
            hasPull: false,
            hasEventPull: false,
            info: {},
            review: {
                visible: false,
                list: []
            },
            page: {
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            },
            popup: {
                visible: false,
                url: ''
            },
            share: {
                visible: false,
                commentId: ''
            },
            shareCompo: {
                visible: false
            },
            params: {}
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.hasLogin = this.$login()
        if(this.hasLogin) {
            this.getReviewListAjax()
        } else {
            this.$router.push({
                name: 'Login'
            })
        }
    },
    methods: {
        // 获取列表
        getReviewListAjax() {
            this.$api.review.getOrderReviewed({
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum,
            }).then(response => {
                const res = response.result || {};
                this.review.list = [...this.review.list, ...res.list]
                this.review.visible = !!this.review.list.length
                this.page.totalPage = res.totalPage
                this.setData()
            })
        },
        setData() {
            this.review.list.forEach((list, cI) => {
                if(!list.orderItem) {
                    list.orderItem = {}
                }
                list.orderItem.rsProp = ''
                if(list.orderItem.propList) {
                    let propArr = [];
                    list.orderItem.propList.forEach(c => {
                        propArr.push(c.specVal)
                    })
                    list.orderItem.rsProp = propArr.join('/')
                }
                if(list.commentInfo) {
                    let gmtCreate = list.commentInfo.gmtCreate;
                    let startStatus = this.setStarStatus(list.commentInfo.commentStar) || {};
                    list.commentInfo.rsGmtCreate = `${this.$dayjs(gmtCreate).format('MMM')} ${this.$dayjs(gmtCreate).format('D')}, ${this.$dayjs(gmtCreate).format('YYYY')}`
                    list.commentInfo.rsStar = startStatus.text
                    list.commentInfo.class = startStatus.class
                }
                if(list.shareTraceStatistics) {
                    list.rsTrace = [{
                        quantity: list.shareTraceStatistics.viewCount,
                        text: this.$translate('View')
                    }, {
                        quantity: list.shareTraceStatistics.purchaseCount,
                        text: this.$translate('Purchase')
                    }, {
                        quantity: `${this.$price(list.shareTraceStatistics.displayCommissionAmount)}`,
                        text: this.$translate('Commission')
                    }]
                }
            })
            this.skeleton = false
        },
        // 星级评价文案
        setStarStatus(val) {
            let arr = [{
                min: 0,
                max: 2,
                text: 'Very Dissatisfied'
            }, {
                min: 2,
                max: 3,
                text: 'Dissatisfied'
            }, {
                min: 3,
                max: 4,
                text: 'Fair'
            }, {
                min: 4,
                max: 5,
                text: 'Satisfied'
            }, {
                min: 5,
                max: 6,
                text: 'Very Satisfied'
            }];
            let r = {
                text: 'Tap to rate',
                class: 'ReviewPersonalList-rate--none'
            };
            let rArr = arr.filter(item => {
                return val >= item.min && val < item.max
            });
            if(val) {
                r = rArr[0]
                r.class = 'ReviewPersonalList-rate--normal'
            }
            r.text = this.$translate(r.text)
            return r
        },
        // 图片预览
        handleImg(obj) {
            this.$ImagePreview({
                images: obj.list.commentInfo.commentPics,
                startPosition: obj.cI
            })
        },
        // 视频播放
        handleVideo(obj) {
            this.popup.visible = true
            this.popup.url = obj.c.url
            let clear = setTimeout(() => {
                let ref = this.$refs['videoPreview'] || [];
                if(ref) {
                    ref.paused == true ? ref.play() : ref.pause()
                }
                clearTimeout(clear)
            }, 100)
        },
        handleProduct(obj) {
            let orderItem = obj.orderItem || {};
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: orderItem.spuCode,
                    seoUrl: orderItem.seoUrl
                }
            })
        },
        // 触底加载更多
        loadMore(e) {
            const first = e && e[0] || {};
            if(first.isIntersecting && this.page.totalPage > this.page.pageNum) {
                this.page.pageNum += 1
                this.hasPull = true
                this.getReviewListAjax()
            }
        },
        // 获取评价配置信息
        getReviewConfigAjax() {
            return new Promise((resolve, reject) => {
                this.$api.review.getOrderReviewConfig({
                    orderId: this.params.order_id, // 短位/长位
                    orderSeq: this.params.order_seq, // 短位
                }).then(response => {
                    const res = response.result || {};
                    this.share.commissionPercentage = res.commissionPercentage
                    resolve(res)
                })
            })
        },
        // 分享
        handlePreShare(obj) {
            let commentInfo = obj.commentInfo || {};
            this.params.order_id = commentInfo.orderSeq
            this.params.order_seq = commentInfo.originOrderId || commentInfo.orderSeq
            this.getReviewConfigAjax().then(res => {
                this.handleShare(obj)
            })
        },
        handleShare(obj) {
            const commentInfo = obj.commentInfo || {};
            const origin = location.origin;
            const userId = this.$storage.get('userId');
            this.share.commentId = commentInfo.id
            this.share.comment = commentInfo.commentContent
            this.share.pic = commentInfo.mainPic

            let queryArr = [
                `shareOrderId=${this.params.order_seq}`,
                `shareCommentId=${this.share.commentId}`,
                `sharePlatform=H5`,
                `shareUserId=${userId}`,
                `accessTime=${new Date().getTime()}`
            ];
            let queryStr = queryArr.join('&');
            this.shareCompo.url = `${origin}/comment/share?${queryStr}`
            this.shareCompo.url = this.shareCompo.url.replace('m.yfn', 'www.yfn');
            this.shareCompo.url = this.shareCompo.url.replace('mmall.', 'pcmall.');
            this.shareCompo.title = this.$translate('I want to share the review with you! Click it out')
            this.shareCompo.description = this.share.comment
            this.shareCompo.img = this.share.pic
            this.shareCompo.hasTip = true
            this.shareCompo.visible = true
            this.shareCompo.shareInfo = [{
                shareOrderId: this.params.order_seq,
                shareCommentId: this.share.commentId,
                sharePlatform: 'H5',
                shareUserId: userId,
                accessTime: new Date().getTime()
            }]
            this.setCommunityData(obj)
        },
        setCommunityData(obj) { // 分享社区参数
            let product = [];
            let media = [];
            let content = '';
            let type = 1;
            if(obj.orderItem) {
                product.push(obj.orderItem)
            }
            if(obj.commentInfo) {
                if(obj.commentInfo.commentPics) {
                    obj.commentInfo.commentPics.forEach(c => {
                        media.push({
                            imageUrl: c
                        })
                    })
                    type = 1
                }
                if(obj.commentInfo.commentVideo) {
                    obj.commentInfo.commentVideo.forEach(c => {
                        media.push({
                            videoUrl: c.url
                        })
                    })
                    type = 2
                }
                if(obj.commentInfo.commentPics || obj.commentInfo.commentVideo) {
                    type = 3
                }
                content = obj.commentInfo.logisticsRemarks || ''
            }
            this.shareCompo.community = {
                product: product, // 商品id集合
                media: media, // 媒体
                content: content, // 评价内容
                type: type, // 媒体类型 1 图文 2 纯视频 3 图文+视频
            }
        },
        // 说明
        handleTip() {
            this.$router.push({
                name: 'Policy',
                query: {
                    id: '169113173796506vXD2Qa'
                }
            })
        },
        handleOrder() {
            this.$router.push({
                name: 'OrderList'
            })
        }
    },
}
