
import ReviewPersonalSet from './ReviewPersonalSet'

export default {
    name: 'ReviewPersonalOrder',
    components: {
        ReviewPersonalSet
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: false,
            skeleton: true,
            orderState: 'WaitComment',
            hasEventPull: false,
            order: {
                visible: false,
                list: []
            },
            page: {
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            },
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        if(this.hasLogin) {
            this.getOrderListAjax()
        } else {
            this.$router.push({
                name: "Login"
            })
        }
    },
    methods: {
        // 获取列表
        getOrderListAjax() {
            this.$api.order.getOrderList({
                orderState: this.orderState,
                ...this.page
            }).then(response => {
                const res = response.result || {};
                this.setTotalData(res)
            })
        },
        setTotalData(res) { // 设置总数据
            this.skeleton = false
            this.order.list = [...this.order.list, ...(res.list || [])]
            this.order.visible = !!this.order.list.length
            this.page.totalPage = res.totalPage
            this.setData()
        },
        setData() { // 设置数据
            this.order.list.forEach(item => {
                let status = this.setStatus(item.orderStatus);
                let resYfnOrderItem = [];
                item.statusText = status.text
                item.statusType = status.type
                item.yfnOrderItem.forEach(c => {
                    // 评分
                    c.rsRate = c.scoreAverage / 10000 || 0
                    // 定制
                    if(c.customizeParams && c.customizeParams[0]) {
                        c.mainPic = c.customizeParams[0].url
                    }
                    // 贺卡
                    resYfnOrderItem.push(c)
                    if(c.greetingCardItems) {
                        c.greetingCardItems.forEach(d => {
                            // 定制
                            if(d.customizeParams && d.customizeParams[0]) {
                                d.mainPic = d.customizeParams[0].url
                            }
                            resYfnOrderItem.push(d)
                        })
                    }
                })
                item.resYfnOrderItem = resYfnOrderItem
            })
        },
        // 设置订单状态
        setStatus(val) {
            // type-> 订单状态 1 待支付 2 待发货 3 待收货 4 完成 5 已取消 6 退款关闭  7 待评价 8 待审核
            let statusTextArr = ['Unpaid', 'Processing', 'Shipped', 'Delivered', 'Cancelled', 'Refunded', 'Reviews', 'Pending'];
            let status = ['pending', 'unshipped', 'shipped', 'done', 'canceled', 'close', '', 'pendingaudit'];
            let type = status.indexOf(val.toLowerCase()) || 0;
            return {
                type: type + 1,
                text: this.$translate(statusTextArr[type])
            }
        },
        // 跳转订单详情
        jumpDetail(obj) {
            this.$router.push({
                name: 'OrderDetail',
                query: {
                    id: obj.originOrderId
                }
            })
        },
        // 触底加载更多
        loadMore() {
            if(this.hasEventPull) {
                return
            }
            pullRefresh.load((e) => {
                this.hasEventPull = true
                if(e.isIntersecting && this.page.totalPage > this.page.pageNum) {
                    this.page.pageNum += 1
                    this.getOrderListAjax()
                }
            })
        },
        handleOrder() {
            this.$router.push({
                name: 'OrderList'
            })
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login()
        }
    },
}
