
export default {
    name: 'ShareTop',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        query: Object,
    },
    data() {
        return {
            top: {
                visible: false,
                orgList: [],
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getReviewInfo()
    },
    methods: {
        getReviewInfo() {
            this.$api.review.getOrderReviewShareProduct({
                shareOrderId: this.query.shareOrderId, // 短位订单id
                shareCommentId: this.query.shareCommentId, // 分享id
                sharePlatform: this.query.sharePlatform, // 分享平台
                shareChannel: this.query.shareChannel, // 分享渠道
                shareUserId: this.query.shareUserId, // 分享人id
                accessTime: this.query.accessTime
            }).then(response => {
                const res = response.result || [];
                this.top.orgList = res
                this.top.visible = !!this.top.orgList.length
                this.setListData()
                this.setData()
            })
        },
        setData() {
            let six = 6;
            let sixArr = [];
            let listArr = [];
            this.top.orgList.forEach((p, i) => {
                // 数据填充
                if(!(i % six)) {
                    if(sixArr.length) {
                        listArr.push(sixArr)
                    }
                    sixArr = []
                }
                sixArr.push(p)
            })
            if(sixArr.length) {
                listArr.push(sixArr)
            }
            this.top.list = listArr
            this.setTopData()
        },
        setListData() {
            this.top.orgList.forEach(p => {
                // 评分
                p.rsRate = p.scoreAverage / 1000
                // 图片
                let pic = (p.pics && p.pics[0] && p.pics[0].url) || '';
                p.rsPic = pic
                // 金额
                p.hasOrgPrice = p.orgPrice > p.salePrice
                p.rsSalePrice = `${this.$price(p.displaySalePrice)}`
                p.rsOrgPrice = `${this.$price(p.displayOrgPrice)}`
            })
        },
        setTopData() {
            this.top.list.forEach(p => {
                p.forEach(c => {
                    // 评论条数
                    c.rsCommentTime = c.commentTime > 999 ? '999+' : c.commentTime
                })
            })
        },
        handleProduct(obj) {
            window.location.href = `/${obj.seoUrl}`
        }
    },
}
