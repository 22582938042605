import { render, staticRenderFns } from "./ReviewPersonalOrder.vue?vue&type=template&id=625f6647&scoped=true&"
import script from "./ReviewPersonalOrder.vue?vue&type=script&lang=js&"
export * from "./ReviewPersonalOrder.vue?vue&type=script&lang=js&"
import style0 from "./ReviewPersonalOrder.vue?vue&type=style&index=0&id=625f6647&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "625f6647",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnSwiper: require('/home/php1/m-nuxt/components/YfnSwiper.vue').default,YfnRate: require('/home/php1/m-nuxt/components/YfnRate.vue').default})
