
export default {
    name: 'ShareInfo',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        query: Object,
        info: Object,
        comment: Object
    },
    data() {
        return {
            popup: {
                visible: false,
                url: ''
            },
            bagCompo: {
                info: {},
                visible: false,
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 图片预览
        handleImg(obj) {
            this.$ImagePreview({
                images: obj.list.commentInfo.commentPics,
                startPosition: obj.cI
            })
        },
        // 视频播放
        handleVideo(obj) {
            this.popup.visible = true
            this.popup.url = obj.c.url
            this.$fnApi.debounce(() => {
                const ref = this.$refs['videoPreview'] || [];
                ref.paused == true ? ref.play() : ref.pause()
            }, 100)
        },
        // 跳转
        handleProduct(obj) {
            this.$router.push({
                name: 'Product-Seo',
                query: {
                    code: obj.orderItem.spuCode,
                    seoUrl: obj.orderItem.seoUrl
                }
            })
        },
        handleBag(obj) {
            const orderItem = obj.orderItem || {};
            this.bagCompo.visible = true
            this.bagCompo.info = {
                productCode: orderItem.spuCode,
                seoUrl: orderItem.seoUrl
            }
        }
    },
}
