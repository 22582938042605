
export default {
    name: 'ShareRec',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            rec: {
                visible: false,
                list: []
            },
            page: {
                pageNum: 1,
                pageSize: 20,
                totalPage: 0
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getRecAjax()
    },
    methods: {
        getRecAjax() {
            this.$api.common.getRecV5Common({
                index: 1,
                pageNum: this.page.pageNum,
                pageSize: this.page.pageSize,
            }).then(response => {
                const res = response.result || {};
                this.rec.list = [...this.rec.list, ...(res.list || res.recommendList || [])]
                this.rec.visible = this.rec.list.length
                this.page.totalPage = res.totalPage
            })
        },
        emitBottomLoading(obj, callBack) { // 触底加载更多
            const hasNext = this.page.totalPage > this.page.pageNum;
            if(obj.isIntersecting && hasNext) {
                this.$fnApi.debounce(() => {
                    this.page.pageNum += 1
                    this.getRecAjax()
                }, 200)
            }
            callBack(!hasNext)
        },
    },
}
