
export default {
    name: 'ReviewSuccessOrder',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        query: Object,
    },
    data() {
        return {
            hasLogin: false,
            collapse: [],
            order: {
                visible: false,
                hasMore: false,
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.hasLogin && this.getOrderNoCommentAjax()
    },
    methods: {
        // 获取订单数据
        getOrderNoCommentAjax() {
            this.$api.review.getOrderReviewNo().then(response => {
                const res = response.result || {};
                this.order.list = res.list || [];
                this.order.hasMore = this.order.list.length > 2
                this.order.visible = !!this.order.list.length
                this.setCollapse(1)
            })
        },
        // 设置展示
        setCollapse(val) {
            this.collapse = []
            this.order.list.forEach((item, i) => {
                if(val == 1 && i < 2) {
                    this.collapse.push(item.id)
                }
                if(val != 1) {
                    this.collapse.push(item.id)
                }
            })
        },
        // 展示更多
        handleMore() {
            this.order.hasMore = false
            this.setCollapse(2)
        },
        // 跳转
        jumpOrderDetail(obj) {
            this.$router.push({
                name: 'Review-Detail',
                query: {
                    order_id: obj.id,
                    order_seq: obj.originOrderId
                }
            })
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login()
        }
    },
}
