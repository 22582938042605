
import ShareInfo from './components/ShareInfo'
import ShareTop from './components/ShareTop'
import ShareRec from './components/ShareRec'

export default {
    name: 'ReviewShare',
    layout: 'main-head',
    components: {
        ShareInfo,
        ShareTop,
        ShareRec
    },
    async asyncData({$api, cookies, ...context}) {
        const query = context.query;

        const response = await $api.review.getOrderReviewShareInfo({
            shareOrderId: query.shareOrderId, // 短位订单id
            shareCommentId: query.shareCommentId, // 分享id
            sharePlatform: query.sharePlatform, // 分享平台
            shareChannel: query.shareChannel, // 分享渠道
            shareUserId: query.shareUserId, // 分享人id
            accessTime: query.accessTime
        });
        const res = response.result || [];
        return {
            info: {
                list: res
            }
        }
    },
    props: {},
    data() {
        return {
            skeleton: true,
            query: {},
            info: {},
            comment: {}
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
        this.setData()
    },
    mounted() {
        this.skeleton = false
    },
    methods: {
        setData() {
            let first = this.info.list[0] || {};
            this.comment = first.commentInfo || {}
            this.info.list = this.info.list
            this.info.list.forEach((list, cI) => {
                let spuInfo = list.spuInfo || {};
                if(list.orderItem) {
                    // 金额
                    list.orderItem.hasOrgPrice = spuInfo.orgPrice > spuInfo.salePrice
                    list.orderItem.rsSalePrice = `${this.$price(spuInfo.displaySalePrice)}`
                    list.orderItem.rsOrgPrice = `${this.$price(spuInfo.displayOrgPrice)}`
                    // 评分
                    list.rsRate = list.orderItem.scoreAverage / 1000
                }
                // 属性
                list.orderItem.rsProp = ''
                if(list.orderItem.propList) {
                    let propArr = [];
                    list.orderItem.propList.forEach(c => {
                        propArr.push(`${c.specName}: ${c.specVal}`)
                    })
                    list.orderItem.rsProp = propArr.join('/')
                }
                if(list.commentInfo) {
                    let gmtCreate = list.commentInfo.gmtCreate;
                    let startStatus = this.setStarStatus(list.commentInfo.commentStar || 0) || {};
                    list.commentInfo.rsGmtCreate = `${this.$dayjs(gmtCreate).format('MMM')} ${this.$dayjs(gmtCreate).format('D')}, ${this.$dayjs(gmtCreate).format('YYYY')}`
                    list.commentInfo.rsStar = startStatus.text
                    list.commentInfo.class = startStatus.class
                }
                if(list.shareTraceStatistics) {
                    list.rsTrace = [{
                        quantity: list.shareTraceStatistics.viewCount,
                        text: this.$translate('view')
                    }, {
                        quantity: list.shareTraceStatistics.purchaseCount,
                        text: this.$translate('Purchase')
                    }, {
                        quantity: `${this.$price(list.shareTraceStatistics.commissionAmount)}`,
                        text: this.$translate('Commission')
                    }]
                }
            })
        },
        // 星级评价文案
        setStarStatus(val) {
            let arr = [{
                min: 0,
                max: 2,
                text: 'Very Dissatisfied'
            }, {
                min: 2,
                max: 3,
                text: 'Dissatisfied'
            }, {
                min: 3,
                max: 4,
                text: 'Fair'
            }, {
                min: 4,
                max: 5,
                text: 'Satisfied'
            }, {
                min: 5,
                max: 6,
                text: 'Very Satisfied'
            }];
            let r = {
                text: 'Tap to rate',
                class: 'ShareInfo-rate--none'
            };
            let rArr = arr.filter(item => {
                return val >= item.min && val < item.max
            });
            if(val) {
                r = rArr[0]
                r.class = 'ShareInfo-rate--normal'
            }
            r.text = this.$translate(r.text)
            return r
        },
        // 初始化
        initialSet() {
            this.query = this.$route.query
        }
    },
}
