
import ReviewSuccessStatus from './components/ReviewSuccessStatus'
import ReviewSuccessOrder from './components/ReviewSuccessOrder'
import ReviewSuccessRec from './components/ReviewSuccessRec'

export default {
    name: 'Review-Success',
    components: {
        ReviewSuccessStatus,
        ReviewSuccessOrder,
        ReviewSuccessRec
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            query: {}
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        initialSet() {
            this.query = this.$route.query
        }
    },
}
