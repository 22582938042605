
import ReviewPersonalOrder from './ReviewPersonalOrder'
import ReviewPersonalList from './ReviewPersonalList'

export default {
    name: 'ReviewPersonalSection',
    components: {
        ReviewPersonalOrder,
        ReviewPersonalList
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            ad: {
                pic: '',
                first: {}
            },
            tab: {
                type: 13
            },
            order: {
                visible: false,
                list: []
            },
            page: {
                pageNum: 1,
                pageSize: 20,
                totalPage: 0
            },
            tabCompo: {
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getAdAjax()
        this.initialSet()
    },
    methods: {
        // 获取广告接口
        getAdAjax() {
            this.$api.common.getAd({
                places: this.tab.type,
            }).then(response => {
                const res = response.result || [];
                let first = res[0] || {};
                this.ad.first = first
                this.ad.pic = first.pic
            })
        },
        changeTab() {
            this.$fnApi.debounce(() => {
                this.getAdAjax()
            }, 300)
        },
        handleAd() {
            window.location.href = this.ad.jumpUrl
        },
        // 初始化
        initialSet() {
            this.tabCompo.list = [{
                title: this.$translate('Awaiting  Review'),
                name: 13
            }, {
                title: this.$translate('Reviewed'),
                name: 14
            }]
        }
    },
}
