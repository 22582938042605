import { render, staticRenderFns } from "./ShareRec.vue?vue&type=template&id=6749b08f&scoped=true&"
import script from "./ShareRec.vue?vue&type=script&lang=js&"
export * from "./ShareRec.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6749b08f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnList: require('/home/php1/m-nuxt/components/YfnList.vue').default})
