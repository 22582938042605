
export default {
    name: 'ReviewSuccessStatus',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        query: Object,
    },
    data() {
        return {
            times: 0,
            info: {
                list: [],
                length: 0
            },
            review: {
                mediaLength: 0,
                desc: '',
                hasShare: false,
                maxStar: 0,
                shareText: '',
                pic: '',
                comment: '',
                commissionPercentage: ''
            },
            status: {
                url: '',
                text: '',
                tip: '',
                status: false,
            },
            shareCompo: {
                visible: false
            },
            popup: {
                visible: false
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getOrderCommentStatusAjax()
    },
    methods: {
        // 获取订单数据
        getDetailAjax() {
            this.$api.review.getOrderReviewInfo({
                orderId: this.query.order_id,
                orderSeq: this.query.order_seq
            }).then(response => {
                const res = response.result || {};
                this.times += 1
                this.info.list = res.packageList || []
                this.info.length = this.info.list.length
                this.setData()
            })
        },
        setData() {
            let hasCommentInfo = false
            this.info.list.forEach((p, i) => {
                p.orderItemCommentList.forEach((c, cI) => {
                    if(c.commentInfo) {
                        hasCommentInfo = true
                    }
                    let review = c.commentInfo || {};
                    let commentPicsLength = (review.commentPics || []).length;
                    let commentVideoLength = (review.commentVideo || []).length;
                    if(commentPicsLength || commentVideoLength) {
                        this.review.mediaLength += (commentPicsLength + commentVideoLength)
                    }
                    if(commentPicsLength > 2) {
                        this.review.desc = 'You will win 15%, 25% off coupons after our review'
                    }
                    if(commentVideoLength) {
                        this.review.desc = 'You will win 100% off coupon after our review'
                    }
                    if(commentPicsLength > 2 && commentVideoLength) {
                        this.review.desc = 'You will win 15%, 25% off coupons and 100% off coupon after our review'
                    }
                    if(c.commentStar > 3) {
                        this.review.maxStar = c.commentStar
                    }
                    if(!cI) {
                        this.review.pic = review.mainPic
                        this.review.comment = review.commentContent
                    }
                })
                if(!i) {
                    this.setCommunityData(p)
                }
            })
            if(!hasCommentInfo && this.times < 5) {
                this.getDetailAjax()
                return
            }
            if(this.review.mediaLength) {
                this.review.shareText = 'Share to win more'
                setTimeout(() => {
                    this.review.hasShare = true
                }, 3000)
            } else {
                this.review.shareText = this.review.maxStar > 3 ? 'Share to win more' : 'Get Commission'
            }
        },
        setCommunityData(obj) { // 分享社区参数
            let product = [];
            let media = [];
            let content = '';
            let type = 1;
            if(obj.orderItemCommentList) {
                obj.orderItemCommentList.forEach(p => {
                    if(p.orderItem) {
                        product.push(p.orderItem)
                    }
                    if(p.commentInfo) {
                        if(p.commentInfo.commentPics) {
                            p.commentInfo.commentPics.forEach(c => {
                                media.push({
                                    imageUrl: c
                                })
                            })
                            type = 1
                        }
                        if(p.commentInfo.commentVideo) {
                            p.commentInfo.commentVideo.forEach(c => {
                                media.push({
                                    videoUrl: c.url
                                })
                            })
                            type = 2
                        }
                        if(p.commentInfo.commentPics || p.commentInfo.commentVideo) {
                            type = 3
                        }
                    }
                })
            }
            if(obj.packageComment) {
                content = obj.packageComment.logisticsRemarks
            }
            this.shareCompo.visible = false
            this.shareCompo.community = {
                product: product, // 商品id集合
                media: media, // 媒体
                content: content, // 评价内容
                type: type, // 媒体类型 1 图文 2 纯视频 3 图文+视频
            }
        },
        // 获取订单评价状态
        getOrderCommentStatusAjax() {
            this.$api.review.getOrderReviewStatus({
                orderId: this.query.order_id,
                orderSeq: this.query.order_seq,
            }).then(response => {
                const res = response.result || {};
                let status = res.commentStatus;
                let review_status = this.query.review_status;
                status = review_status == 1 ? false : status
                this.status.status = status
                this.status.url = status ? 'icon-success' : 'icon-warning'
                this.status.text = this.$translate(`${status ? 'Submitted Successfully' : 'This order has been reviewed, please do not repeat review'}`)
                this.status.tip = this.$translate(`${status ? 'Thank you for your review' : ''}`)
                if(this.status.status) {
                    this.getDetailAjax()
                    this.getReviewConfigAjax()
                }
            })
        },
        // 获取评价配置信息
        getReviewConfigAjax() {
            this.$api.review.getOrderReviewConfig({
                orderId: this.query.order_id,
                orderSeq: this.query.order_seq,
            }).then(response => {
                const res = response.result || {};
                this.review.commissionPercentage = res.commissionPercentage
            })
        },
        // 跳转
        jumpReviewDetail() {
            this.$router.push({
                name: 'Review-Detail',
                query: {
                    order_id: this.query.order_id,
                    order_seq: this.query.order_seq
                }
            })
        },
        jumpHome() {
            this.$router.push({
                name: 'Home',
            })
        },
        // 提示
        handleTip() {
            this.$router.push({
                name: 'Policy',
                query: {
                    id: '169113173796506vXD2Qa'
                }
            })
        },
        // 分享
        handleShare() {
            this.query = this.$route.query;
            const origin = location.origin;
            const userId = this.$storage.get('userId');
            const queryArr = [
                `shareOrderId=${this.query.order_id}`,
                `shareCommentId=`,
                `sharePlatform=H5`,
                `shareUserId=${userId}`,
                `accessTime=${new Date().getTime()}`
            ];
            this.shareCompo.shareInfo = [{
                shareOrderId: this.query.order_id,
                shareCommentId: '',
                sharePlatform: 'H5',
                shareUserId: userId,
                accessTime: new Date().getTime()
            }]
            const queryStr = queryArr.join('&');
            this.shareCompo.url = `${origin}/comment/share?${queryStr}`
            this.shareCompo.url = this.shareCompo.url.replace('m.yfn', 'www.yfn');
            this.shareCompo.url = this.shareCompo.url.replace('mmall.', 'pcmall.');
            this.shareCompo.title = this.$translate('I want to share the review with you! Click it out')
            this.shareCompo.description = this.review.comment
            this.shareCompo.img = this.review.pic
            this.shareCompo.hasTip = true
            this.shareCompo.visible = true
        }
    },
}
